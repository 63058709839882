/*!

=========================================================
* BRO GAS - v1.0.0
=========================================================

* Copyright 2022 BRO GAS
* Coded by:  Abraham Mitiku

=========================================================

*
*/
/**
 * Public Views
 * !Public view pages should be imported here and add with public view categories
 */
import React, {Suspense} from 'react';
import BroBackdrop from '../components/layouts/feedbacks/BroBackdrop';
const Home = React.lazy(() => import('../components/views/public/Home'));

// layout
const PublicLayout = React.lazy(() => import('../components/layouts/PublicLayout'));
  
 const routes = [
  /**
   *  Public Views
   *  Directories: 
   *  Views -> public - > [Home] 
   */ 
  {
  path: "/",
  element:  <Suspense 
    fallback={
    <BroBackdrop
    open={true}
    backdrop={true}
    />
    }
  >
      <PublicLayout />
    </Suspense>,
    children: [
      { 
        index: true,
         element: <Suspense 
         fallback={
          <BroBackdrop />
         }
       ><Home />  </Suspense>
      },
      {
          path: "/home",
          element: <Suspense 
          fallback={
            <BroBackdrop />
          }
        ><Home />  </Suspense> ,
      },
      {
        path: "/wait",
        element: <BroBackdrop /> ,
      },
      ],
  },
 
  ];
  export default routes;