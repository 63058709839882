const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === "light"
      ? {
          // LIGHT MODE
          primary: {
            main: `#9e9e9e`,
            dark: `#616161`,
            light: `#6e6e6e`,
          },
          secondary: {
            main: `#218C6F`,
            dark: `#217E65`,
            light: `#13A77F`,
          },
          info: {
            main: `rgba(255, 255, 255, 0.7)`,
            dark: `rgba(255, 255, 255, 0.7)`,
            light: `rgba(255, 255, 255, 0.7)`,
          },
          text: {
            primary: "#000000",
            secondary: "#616161",
          },
          background: {
            default: "#e2e2e2",
            paper: "#eeeeee",
          },
        }
      : {
          // DARK MODE
          primary: {
            main: `#ffbb33`,
            dark: `#b28223`,
            light: `#ffc85b`,
          },
          secondary: {
            main: `#ffeb3b`,
            dark: `#b2a429`,
            light: `#ffef62`,
          },
          info: {
            main: `rgba( 20, 25, 34, 0.9)`,
            dark: `rgba(0, 169, 244, 0.4)`,
            light: `rgba( 20, 25, 34, 0.9)`,
          },
          background: {
            default: "#182643",
            paper: "#1c2a48",
          },
          text: {
            primary: "#ffffff",
            secondary: "#bdbdbd",
          },
        }),
  },
});

export default getDesignTokens;
