/*!

=========================================================
* BRO GAS - v1.0.0
=========================================================

* Copyright 2022 BRO GAS
* Coded by:  Abraham Mitiku

=========================================================

*
*/
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './assets/css/siteStyle.css';
import {BrowserRouter as Router} from 'react-router-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// initial app
const InitialApp = () =>{
  return(
  <Router>
  <App/>
  </Router>
  )
 };
 
 ReactDOM.render(
   <InitialApp />,
   document.getElementById('root')
 );

serviceWorkerRegistration.register();
