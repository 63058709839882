/*!

=========================================================
* BRO GAS - v1.0.0
=========================================================

* Copyright 2022 BRO GAS
* Coded by:  Abraham Mitiku

=========================================================

*
*/
import React from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import getDesignTokens from '../../../constants/theme';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import siteLogo from '../../../assets/images/am.png';
import { SettingsRemoteOutlined } from '@mui/icons-material';

const CircularProgressWithLabel = (props) =>{
    return (
      <>
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} color="secondary.dark" />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography sx={{fontWeight: 600}} color="secondary.dark">
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
        <Box
        sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mt:10
        }}
        >
            <Typography
            variant="h6"         
            sx={{fontWeight: 600, color: '#000000'}}
            >
            MERN STACK, <b style={{color: `#ff9800`}}>WEB DEVELOPER</b>
            </Typography>
        </Box>
        <Box
        sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mt:14
        }}
        >
            <Typography
            color="secondary.main"
            sx={{fontWeight: 600}}
            >
            FREELANCER
            </Typography>
        </Box>
      </>
    );
  }
  
CircularProgressWithLabel.propTypes = {
value: PropTypes.number.isRequired,
};

const BroBackdrop = ({children}) => {
    const [mode, setMode] = React.useState('dark');
    const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);
    const [loading, setLoading] = React.useState(true);
    
    React.useEffect(() => {
        const timeout = setTimeout(() => {
          setLoading(false)
        },3000);
        return () => {
          clearTimeout(timeout);
        };
    }, []);

    return (
        <>
       {
         loading?
         <>
         <ThemeProvider theme={theme}>
        <Backdrop
        sx={{ 
        color: '#218C6F', 
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: 'background.default',
        display: 'block'
        }} 
        open={true} 
        >
          <Box sx={{display: 'flex', bgcolor: 'transparent'}}>
              <Box
              sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              ml:4,
              cursor: 'pointer'
              }}
              >
                <Box>
                  <Typography className=' site-text' color='secondary.light' variant='h6' sx={{mt:2, fontWeight: 700}}>BRO GAS</Typography>
                  <Typography className='' color='text.secondary' sx={{fontSize: 8}}>Design & Manufacture Oxygen</Typography>
                </Box>
              </Box>
              <Box sx={{flexGrow: 1}}/>
          </Box>
         <Box
          sx={{
            
            textAlign: 'center',
            mt:10,    
          }}
          >
           <CircularProgress color='secondary' size={110} thickness={10} />
           
          </Box>
          <Box
          sx={{
            justifyContent: 'flex-start',
            p:2,
            mt: 15
          }}
          
          >
          <Typography className='other-text secondary-text' variant='h6' color='text.secondary' sx={{}}>WELCOME</Typography>
            <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start'
            }}
            
            >
            <Typography className='landing-text' variant='h2' sx={{mt:1, color: 'white', fontWeight: 800,cursor: 'pointer'}}>WAIT A BIT</Typography>
            </Box>
          </Box>
          <Box 
          sx={{
            mt: 15,
            display: 'flex',
            justifyContent: 'center',
          }}
          >
            <Typography className='other-text' color='text.secondary' sx={{fontSize: 10}}> 2022 Bro Gas.</Typography>
          </Box>
        </Backdrop>
        </ThemeProvider>
         </>
         :
         <>
         {children}
         </>
       }
    </>
    );
};

BroBackdrop.propTypes = {
color: PropTypes.string,
open: PropTypes.bool,
appLayer: PropTypes.bool
};

export default BroBackdrop;